import { createStore } from "vuex";

export default createStore({
  state: {
    language: "en",
    languageName: "English",
  },
  mutations: {},
  actions: {},
  modules: {},
});
