<template>
<Hero :content="hero"/>
</template>

<script>
import Hero from '@/components/Hero';


export default {
    components:{Hero},
    setup(){

        const hero={
            background: false,
            title:"<strong>We develop website and web platforms</strong>",
            subtitle:"A quality website will empower your business in all of its aspects!",
            deco:[
                {
                    svg: "webdesign",
                },
                {
                    svg:"vuejs"
                },
                {
                    svg:"database"
                }
            ],
            cta:[
                {
                    target:"",
                    label:""
                }
            ],
            scroll:{
                color:'blue'
            }
        }

        return{
            hero
        }
    }

}
</script>

<style lang="scss">
@import '@/style/main';


</style>