<template>
  <Header/>
  <How/>
  <Solutions />
  <Method />
  <About />
  <Portfolio />
  <Faq/>
</template>

<script>
import { defineAsyncComponent, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router';
import {scrollTo} from '@/composables/scroll';

const About  = defineAsyncComponent(() => import('@/components/About'));
const Header = defineAsyncComponent(() => import('@/components/Header'));
const How  = defineAsyncComponent(() => import('@/components/How'));

const Portfolio  = defineAsyncComponent(() => import('@/components/Portfolio.vue'));
const Solutions  = defineAsyncComponent(() => import('@/components/Solutions'));
const Faq  = defineAsyncComponent(() => import('@/components/Faq'));
const Method  = defineAsyncComponent(() => import('@/components/Method'));
export default {
  components:{About, Faq, Header, How,Method, Portfolio, Solutions},
  setup(){

    const route = useRoute();
    const pageName = route.name;
    
    onMounted(() => {
          if(route.params.section){
            scrollTo(route.params.section);
      }
    })
    watch( () => route.params, (newVal, oldVal) => {

        if(newVal.section && pageName === route.name){
            scrollTo(newVal.section);
      }
})
     
  }
}
</script>

<style>

</style>