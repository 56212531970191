import { UNREF } from "@vue/compiler-core";
import $ from "jquery";

export const smoothScroll = (anchor) => {
  let target = document.getElementById(anchor);
  let headerOffset = document.getElementById("nav").offsetHeight;
  let elementPosition = target.offsetTop;
  let offsetPosition = elementPosition - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const scrollTo = async (target, offset = 0) => {
  console.log("in", target);
  const offsetNav = $("#navigation").height(); //$("#navigation").height();
  console.log(offsetNav);
  if (!$("#" + target).length) {
    await delay(500); //ON attend que les éléments soient chargés (si la page n'était pas encore chargée)
  }

  animate(target, offsetNav, offset);
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const animate = (target, offsetNav, offset) => {
  $("html, body").animate(
    {
      scrollTop: $("#" + target).offset().top - offsetNav - offset,
    },
    800
  );
};
