<template>
<Heading />
<header class="background">
     <div class="header__content">
            <h1 v-html="content.title"></h1>
            <h2 v-html="content.subtitle"></h2>
            <div class="header__actions">
                <button class="btn btn--blue" @click="smoothScroll('how')">
                    mission
                </button>
                <button class="btn btn--white" @click="smoothScroll('footer')">
                    contact
                </button>
            </div>
      </div>
      <div class="header__line"></div>
      <div class="header__deco">
          <div v-for="(d, i) of content.deco" class="header__deco--item" :class="`header__deco--${i+1}`">
              <div class="header__deco--logo " :class="`header__deco--logo-${i+1}`" >
                  <Svg className="header__scroll--svg" :icon="d.svg" size="4"/>
              </div>
              
          </div>
      </div>
        <button class="header__scroll" :class="`header__scroll--${content.scroll.color}`" @click="smoothScroll(content.scroll.target)" aria-label="scroll down" >
              <Svg className="header__scroll--svg" icon="scroll"/>
          </button>
      <img v-if="content.background" src="@/assets/img/doe-mountain.svg"   alt="mountain background svg" class="background__image">
</header>
 
</template>

<script>

import Svg from '@/components/utils/Svg';
import {smoothScroll} from '@/composables/scroll';

import {useI18n} from 'vue-i18n';

export default {
    components:{Svg},
    props:['content'],
    setup(){

          const {t} = useI18n({
            messages:{
              en: require('@/locales/en/header.json'),
              fr: require('@/locales/fr/header.json')
            }
        })

        return{
            smoothScroll,
            t
        }
    }

}
</script>

<style lang="scss">
@import '@/style/main';

$border-bottom: 2rem;

.background{
    margin-top: $head-height;
    position: relative;
    background-color: $color-bck-blue-light;
    height: calc(100vh - #{$head-height});
    display: flex;
    flex-direction: column;

    border-bottom: $border-bottom solid $color-blue-main;

    @include respond(phone){
        height: calc(90vh - #{$head-height});
    }
    &__image{
        margin-top: auto;
        width: 100%;
    }
}


$header-top-large: 22%;
$header-left-large: 26.5%;

$header-top: 20%;
$header-left: 20%;

$header-top-phone: 15%;
$header-left-phone: 10%;

.header{

    &__content{
        position: absolute;
        top: $header-top;
        left: $header-left;
        padding-right: 6rem;

        @include respond(phone){
            top: $header-top-phone;
            left: $header-left-phone;
        }
        @include respond(large-desktop){
            top: $header-top-large;
            left: $header-left-large;
        }

        & h1{
            font-size: 3.5rem;
            font-weight: 300;
            font-style: italic;
            line-height: 4.5rem;

            @include respond(large-desktop){
                 font-size: 4.5rem;
                 line-height: 6.5rem;
            }

            @include respond(small){
                font-size: 3rem;
                line-height: 3.5rem;
            }
        }

        & strong{
            font-weight: 700;
        }

        & h2{
            font-weight: 400;
            margin-top: 1rem;
        }
    }




    &__actions{
        margin-top: 2.5rem;
        display: flex;

        & button{
            &:not(:last-child){
                margin-right: 1.6rem;
                @include respond(phone){
                    margin-right: 0;
                    margin-bottom: 1.6rem;
                }
            }
        }

        @include respond(phone){
            flex-direction: column;
        }
    }

    &__line{
        position: absolute;
        top: calc(#{$header-top} + .6rem);
        left: calc(#{$header-left} - 2rem);
        height: calc(100% - #{$header-top} - .6rem);
        width: 5px;
        background-color: $color-blue-main;

         @include respond(phone){
           top: calc(#{$header-top-phone} + .6rem);
            left: calc(#{$header-left-phone} - 2rem);
            height: calc(100% - #{$header-top-phone} - .6rem);
        }
        @include respond(large-desktop){
           top: calc(#{$header-top-large} + .6rem);
            left: calc(#{$header-left-large} - 2rem);
            height: calc(100% - #{$header-top-large} - .6rem);
        }
    }

    &__scroll{
        @include buttonNoStyle();
        position: absolute;
        top: 94%;
        left: 50%;

        transform: translate(-50%, -50%);
        color:$color-white;
        animation: scroll 4s infinite;

        

        &--blue{
            color:$color-blue-main;
        }

        @keyframes scroll {
            0% {
                transform: translate(-50%, -50%)
            }
            5% {
                transform: translate(-50%, -25%)
            }
            10%{
                transform: translate(-50%, -75%)
            }
            15% {
                transform: translate(-50%, -50%)
            }
        }

        &--svg{
            @include svg(4rem);
        }

        @include respond(phone){
            display: none;
        }
    }

    &__deco{
        position: absolute;

        width: 23rem;
        height: 50%;
        bottom: 0;
        left: 65%;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        $logo-height: 7.5rem;
        &--logo{
            
            display: flex;
            align-items: center;
            justify-content: center;
            color:$color-white;
            height: $logo-height;
            width: $logo-height;
            border-radius: 50%;
            box-shadow: $shadow;

            z-index: 10;

            transition: all .2s;

            &:hover{
                transform: scale(1.3);
            }

            &-1{
                background-color:$color-blue-main;
            }
            &-2{
                background-color:$color-blue-dark;
            }
            &-3{
                background-color:$color-rose;
            }
        }

        &--item{
            position: relative;
            &::after{
                position: absolute;
                content:"";
                height: calc(100% - #{$logo-height});
                width: .6rem;
                background-color: currentColor;
                left: 50%;
                bottom: 0;

                z-index: 0;
                
                transform: translateX(-50%);
            }
        }



        &--1{
            height: 80%;
            color:$color-blue-main;
            animation: rise1 1s ease-in-out;
        }
        &--2{
            height: 100%;
            color:$color-blue-dark;
            animation: rise2 1.5s ease-in-out;
        }
         &--3{
            height: 70%;
            color:$color-rose;
            animation: rise3 2s ease-in-out;
        }

    }
}

@keyframes rise1{
    0% {
      height: 0%;
    }
    50% {
      height: 50%;
    }
    100% {
      height: 80%;
    }
  }

  @keyframes rise2{
    0% {
      height: 0%;
    }
    50% {
      height: 60%;
    }
    100% {
      height: 100%;
    }
  }


  @keyframes rise3{
    0% {
      height: 0%;
    }
    50% {
      height: 40%;
    }
    100% {
      height: 70%;
    }
  }

</style>