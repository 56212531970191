<template>
  <navigation></navigation>
  <router-view/>
  <Footer/>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import Navigation from '@/components/Navigation';
const Footer  = defineAsyncComponent(() => import('@/components/Footer'));
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'; 
import { watch } from 'vue'
import { languages } from "@/composables/languages";

export default {
  components:{Navigation, Footer},
  setup(){
    const store = useStore();
    const {locale}  = useI18n();
   const langues = languages();
  
    //Define language from change
      watch(store.state, (n, o) => {
        if(n.language) {
          locale.value = n.language;
          store.state.languageName = langues.filter(el => el.locale === store.state.language)[0].name

          const html = document.documentElement // returns the html tag
          html.setAttribute('lang', locale.value)
        }
      })
  }

}
</script>


<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');
@import 'style/main';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
}


#app {
  font-family: Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text;

  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
	padding: 0rem; /*Mets une bordure au site*/

  ::selection {
    background-color: $color-blue-main;
    color: $color-white;
  }

}
</style>
