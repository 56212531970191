<template>
  <div class="ban">
      <div class="container__section ban__content">
          <ul>
              <li v-for="o of data">
                  <a :href="o.href"><Svg :icon="o.svg" size="1.6" className="ban__content--svg"/> {{o.label}}</a>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg'

export default {
    components:{Svg},
    setup(){
        const data = [
            {
                svg:"phone-2",
                href:"tel:0032497331008",
                label:"+32 497 33 10 08"
            },
            {
                svg:"email",
                href:"mailto:info@onearth.be",
                label:"info@onearth.be"
            }
        ]

        return {data}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';

.ban{
    background-color: $color-blue-main;
    color:$color-white;
    height: $ban-height;


    &__content{

        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & ul {
            display: flex;
        }

        & li{
            list-style: none;

            &:not(:last-child){
                margin-right: 3rem
            }
        }

        & a{
            @include buttonNoStyle;
            display: flex;
            align-items: center;
            font-size: 1.6rem;
        }

        &--svg{
            margin-right: 1rem;
            display: flex;
            align-items: center;
        }
        
    }
}

</style>