<template>
    <div id="navigation" class="nav__container">
        <banniere></banniere>
        <div id="nav" class="nav">
            <div class="nav__content container__section">
                <div class="nav__logo">
                    <img src="@/assets/img/doe-logo.svg" width="581" height="397" alt="logo Digital On Earth" class="nav__logo--img">
                </div>
                <nav class="nav__items">
                    <router-link :to="{name:'Home', params:{section: 'how'}}" class="btn__text nav__items--item u-phone-hide">{{t('how')}}</router-link>
                    <router-link :to="{name:'Home', params:{section: 'solutions'}}" class="btn__text nav__items--item u-phone-hide">{{t('solutions')}}</router-link>
                    <router-link :to="{name:'Home', params:{section: 'portfolio'}}" class="btn__text nav__items--item u-phone-hide">{{t('portfolio')}}</router-link>
                    <router-link :to="{name:'Hosting'}" class="btn__text nav__items--item u-tab-hide">
                        Hosting
                    </router-link>
                    <button class="nav__menu" aria-label="side menu" @click="displaySide = true">
                        <div class="nav__menu--bar"></div>
                    </button>
                    <button class="btn btn__small btn--blue nav__items--item u-phone-hide" @click="scrollTo('footer')">{{t('contact')}}</button>
                
                    <div class="nav__lang u-phone-hide">
                        <button class="btn__text nav__lang--item" @click="setLanguage('fr')">
                            Fr
                        </button>
                        <button class="btn__text nav__lang--item" @click="setLanguage('en')">
                            En
                        </button>
                    </div>
                
                </nav> 
            </div>
            <div  class="nav__side" :class="{'nav__side--hide': !displaySide, 'nav__side--show': displaySide}">
                <button class="side__quit" aria-label="close side menu" @click="hideSide">
                    <Svg icon="delete" className="side__quit--svg"/>
                </button>
                <div class="nav__side--logo">
                    <img src="@/assets/img/doe-logo-white.svg" alt="logo digital on earth white">
                </div>
                
                <ul class="side">
                    <li v-for="s in menuSide" :key="s.target" @click="scrollTo(s.target)">
                        <button class="side__btn">
                            {{s.text}}
                        </button>
                    </li>
                </ul>
                <a class="nav__side--cta" href="mailto:info@onearth.be">
                    <Svg icon="email" className="nav__side--cta-svg"/>info@onearth.be
                </a>
                <ul class="nav__side--lang">
                    <li>
                        <button @click="setLanguage('fr')">
                            Français
                        </button>
                    </li>
                <li>
                        <button @click="setLanguage('en')">
                            English
                        </button>
                </li>

                </ul>

            </div>
        </div>
    </div>


</template>

<script>
import { computed, ref } from 'vue'
import Svg from './utils/Svg'
import {smoothScroll} from '../composables/scroll';

import {useI18n} from 'vue-i18n';
import { useStore } from 'vuex';
import {useRoute} from 'vue-router';
import Banniere from '@/components/Banniere.vue';

export default {
    components:{
        Banniere,
        Svg
    },
    setup(){
        const displaySide = ref(false);
        const store = useStore();
        const route = useRoute();

        const {locale} = useI18n({})
        const {t} = useI18n({
            messages:{
              en: require('../locales/en/nav.json'),
              fr: require('../locales/fr/nav.json')
            }
        })

        console.log(route.name);

        const scrollTo = (target) => {
            smoothScroll(target);
            hideSide();
        }

        const hideSide = () => {
            displaySide.value =false;
        }

        const setLanguage = (lang) => {
            locale.value = lang;

            store.state.language = lang;

            //Router
            route.query.locale = lang;
            history.pushState({}, null,  route.path+'?locale='+lang)
        }

        const menuSide = computed(() =>[
            {
                "text": t('how'),
                "target": "how"
            },
            {
                "text": t('solutions'),
                "target": "solutions"
            },
            {
                "text": t('method'),
                "target": "method"
            },

              {
                "text": t('portfolio'),
                "target": "portfolio"
            },
            {
                "text": t('faq'),
                "target": "faq"
            },
             {
                "text": t('about'),
                "target": "about"
            },
            
        ])

        return {displaySide, hideSide, menuSide, scrollTo,setLanguage,  t}
    }
}
</script>

<style lang="scss">
@import '@/style/main';


.nav{

    &__container{
         position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }


    height: $nav-height;
    box-shadow: $shadow;
   
    background-color: $color-white;


    &__content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        
        padding: 1.2rem 0;
        height: 100%;
    }

    &__menu{
        
        @include buttonNoStyle;
        align-self: stretch;
        display: flex;
        align-items: center;
        margin-right: 2rem;

        @include respond(phone){
            margin-right: 0;
        }
      

        &--bar{
            position: relative;
            width: 3rem;
            height: 3px;
            background-color: currentColor;
            border:none;padding: 0;
            &::before{
                content:"";
                position: absolute;
                left: 0;
                top: -1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
                
            }
            &::after{
                content:"";
                position: absolute;
                left: 0;
                top: 1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
            }

        }

        &:hover{
            color:$color-blue-main;
        }

        &:hover > &--bar{
            &::before{
                top: -1.5rem;
            }
            &::after{
                top: 1.5rem;
            }
        }
    }


    &__logo{
        height: 100%;
        width: auto;

        &--img{
            height: 100%;
            width: auto;
        }

    }

    
    &__lang{
        border-left: 1px solid currentColor;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;

        &--item{
            &:not(:last-child){
                margin-bottom: .6rem;
            }
        }
    }

    &__items{
        height: 100%;
        display: flex;
        align-items: center;

        &--item{
            &:not(:last-child){
                margin-right: 2rem;
            }
        }
        
    }

    &__side{
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 35vw;
        height: 100vh;
        background-color: $color-blue-dark;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        color: $color-white;

        @include respond(tab-port){
            width: 50vw;
        }

        @include respond(phone){
            width: 100vw;
        }

        &--hide{
            transition: all .4s;
            right: -100vw;
        }

        &--show{
             transition: all .4s;
            right: 0vw;
        }


        &--logo{
            display: flex;
            justify-content: center;
            & img{
                width: 15rem;
                height: auto;
            }
        }

        &--cta{
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: 3rem;
            width: 100%;
            padding: 1rem 1.8rem;
            text-align: center;
            color:$color-blue-main;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: .1rem;
            background-color: $color-white;
            box-shadow: $shadow;

            display: flex;
            align-items: center;
            justify-content: center;

            transition: all .2s;
            &:hover{
                transform: scale(1.05);
            }

            &-svg{
                @include svg(2.6rem);
                margin-right: 2rem;
            }
        }

        &--lang{
            display: flex;
            justify-content: center;
            & li{
                list-style: none;
                padding: .5rem 1.6rem;
                &:not(:last-child){
                    position: relative;

                    &::before{
                        content: '';
                        position: absolute;
                        left: 100%;
                        top: 0;
                        height: 100%;
                        width: .2rem;
                        background-color: currentColor;
                    }
                }
            }
            &  button{
                
                @include buttonNoStyle;

                @include hoverUnderline;
                font-size: 1.6rem;

                
            }
        }
        
        
    }
}

.side{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & li{
        list-style: none;
        width: 100%;
        padding: 1.6rem 2.4rem;
        cursor: pointer;
        


        &:hover{
            background-color: $color-white;
            color:$color-blue-main;
        }
    }

    & button{
        @include buttonNoStyle;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.6rem;
        letter-spacing: .2rem;
    }

    &__quit{
        @include buttonNoStyle;
        position: absolute;
        top: 2rem;
        right: 2rem;

        transition: all .2s;

        &--svg{
            @include svg(2.4rem);
        }

        &:hover{
            transform: scale(1.1);
        }
    }
}

</style>