import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Development from "../views/Development.vue";

import store from "@/store/index";
import { languages } from "@/composables/languages";

const routes = [
  {
    path: "/:section?",
    name: "Home",
    component: Home,
  },
  {
    path: "/hosting",
    name: "Hosting",
    component: () =>
      import(/* webpackChunkName: "hosting" */ "@/views/Hosting.vue"),
  },
  {
    path: "/development",
    name: "Development",
    component: Development,
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //We keep locale between pages
  if (from.query && from.query.locale && to.query && !to.query.locale) {
    next({ path: to.path, query: { locale: from.query.locale } });
    return;
  }

  // SET LANGUAGE
  if (to.query && to.query.locale && to.query.locale !== from.query.locale) {
    const langues = languages();

    store.state.language = to.query.locale;
    store.state.languageName = langues.filter(
      (el) => el.locale === store.state.language
    )[0].name;
  }

  // const nearestWithTitle = to.matched
  //   .slice()
  //   .reverse()
  //   .find((r) => r.meta && r.meta.title);

  // // Find the nearest route element with meta tags.
  // const nearestWithMeta = to.matched
  //   .slice()
  //   .reverse()
  //   .find((r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched
  //   .slice()
  //   .reverse()
  //   .find((r) => r.meta && r.meta.metaTags);

  // // If a route with a title was found, set the document (page) title to that value.
  // if (nearestWithTitle)
  //   document.title = `${process.env.VUE_APP_TITLE} - ${nearestWithTitle.meta.title}`;

  // // Remove any stale meta tags from the document using the key attribute we set below.
  // Array.from(
  //   document.querySelectorAll("[data-vue-router-controlled]")
  // ).map((el) => el.parentNode.removeChild(el));

  // // Skip rendering meta tags if there are none.
  // if (!nearestWithMeta) return next();

  // // Turn the meta tag definitions into actual elements in the head.
  // nearestWithMeta.meta.metaTags
  //   .map((tagDef) => {
  //     const tag = document.createElement("meta");

  //     Object.keys(tagDef).forEach((key) => {
  //       tag.setAttribute(key, tagDef[key]);
  //     });

  //     // We use this to track which meta tags we create, so we don't interfere with other ones.
  //     tag.setAttribute("data-vue-router-controlled", "");

  //     return tag;
  //   })
  //   // Add the meta tags to the document head.
  //   .forEach((tag) => document.head.appendChild(tag));

  // ROUTE PROTECTION

  next();
});

export default router;
